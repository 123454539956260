import { defineMessages } from 'react-intl'

/* eslint-disable max-len */
export default defineMessages({
  TitleHome: 'Web-development company Düsseldorf | freshcells systems engineering GmbH',
  Title404: '404: Page not found on freshcells.de',
  TitleAboutUs: 'Web-Agency freshcells in detail',
  TitleWhyAgile: 'Agile development at freshcells',
  TitleTechnologies: 'Web-technologies used by freshcells',
  TitleCareer: 'Career in Düsseldorf at freshcells',
  TitleContact: 'Contact freshcells for free consulting',
  TitleImprint: 'Company Information about freshcells',
  TitlePartner: 'Partner for software development at freshcells',
  TitlePrivacyPolicy: 'Privacy Policy for freshcells.de',
  TitlePrivacyPolicyApplicants: 'Privacy Policy for applicants at freshcells',
  TitleProducts: 'Touristic products for tour operators, Amadeus, Peakwork and many more by freshcells',
  TitleMightyC: 'mightyC the content player for touristic platforms by freshcells',
  TitleQuickstart: 'Quickstart - Online travel sales platform by freshcells',
  TitleFusion: 'Fusion - Connect Peakwork, Amadeus or any product source by freshcells',
  TitleTravelsandbox: 'TravelSandbox® - Booking engine framework for the touristic industry by freshcells',
  TitleFreshMS: 'freshMS - Visual content editing for travel booking platforms by freshcells',
  TitleProjectManager: 'Project Manager career in Düsseldorf at freshcells',
  TitleSeniorProjectManager: 'Senior Project Manager career in Düsseldorf at freshcells',
  TitleBusinessDeveloperSalesManager: 'Business Developer / Sales Manager (m/w/d) - Software Development - in Vollzeit',
  TitleSeniorFullStackPHP: 'Senior Full Stack Developer PHP career in Düsseldorf at freshcells',
  TitleSeniorFrontendReact: 'Frontend Developer React career in Düsseldorf at freshcells',
  TitleFullstackDev: 'Fullstack Developer career in Düsseldorf at freshcells',
  TitleTechnicalProjectManager: 'Technical Project Manager career in Düsseldorf at freshcells',
  TitleSeniorArchitect: 'Senior Software Architect career in Düsseldorf at freshcells',
  TitleSeniorJavaDeveloper: 'Senior Java Developer career in Düsseldorf at freshcells',
  TitleSalesDirector: 'Sales Director career in Düsseldorf at freshcells',
  TitleSoftwareArchitect: 'Software Architect career in Düsseldorf at freshcells',
  TitleSoftwareTester: 'Software Tester career in Düsseldorf at freshcells',
  TitleDevOps: 'DevOps Engineer career in Düsseldorf at freshcells',
  TitleAccountant: 'Accountant career in Düsseldorf at freshcells',
  TitleReceptionist: 'Receptionist & office management career in Düsseldorf at freshcells',
  TitleProjectManagement: 'Project Management for IT projects with freshcells',
  TitleFrontendDesigner: 'Frontend Designer career in Düsseldorf at freshcells',
  TitleReferences: 'References of freshcells web-application projects',
  TitleServices: 'Services for web-development, complex applications and webdesign by freshcells',
  TitleStyleguide: 'Style Guide of freshcells.de with our design systems',
  TitleAppDevelopment: 'App Development at freshcells for all platforms',
  TitleConceptStrategy: 'Software Concepts for platform development with freshcells',
  TitleDevelopment: 'Web Application & Platform Development by freshcells',
  TitleItConsulting: 'IT Consulting by development experts by freshcells',
  TitleSystemEngineering: 'Systems Engineering to digitalize your company with freshcells',
  TitleSystemsIntegration: 'System Integration to expand and grow with freshcells',
  TitleTools: 'Tools for web development and design at freshcells',
  TitleDesign: 'UI / UX Design at freshcells Düsseldorf',
  TitleFair: 'Fair information by freshcells',
  TitleEcommerce: 'E-commerce development and design agency freshcells',
  TitleHeadlessCms: 'Headless CMS integration and solutions by freshcells',
  TitleSoftware: 'Software Development for web platforms by freshcells',
  TitleSoftwareB: 'Software Development for web applications by freshcells',
  TitleIBE: 'IBE - Internet Booking Engine for the touristic industry by freshcells',
  TitleNewsRoom: 'Newsroom of freshcells systems engineering GmbH',
  TitleNewsOne: 'freshcells puts technological lead to the test at Aldiana',
  TitleNewsTwo: 'freshcells goes live with Eurowings Holidays after only one month',
  TitleNewsMefa: 'Mitsubishi Electric Factory Automation EMEA relies on freshcells systems engineering and has new customer portals developed for 21 countries',
  TitleNewsRadevormwald: 'City of Radevormwald relies on IT consulting from freshcells',
  TitleNewsUniversal: 'Swiss tour operator Universal Flugreisen AG launches technology partnership with freshcells systems engineering',
  TitleNewsITB2020: 'freshcells shows high-tech IBE at entry-level rate at ITB 2020',
  TitleCaseStudy: 'Case Studies of web development by freshcells',
  TitleHcmsIntegration: 'Headless CMS Integration made right with freshcells',
  TitleStrapi: 'Strapi headless CMS integration partner freshcells',
  TitleContentstack: 'Contentstack headless CMS integration partner freshcells',
  TitleNewsContentstack: 'freshcells and Headless CMS Provider Contentstack Expand Their Partnership',
  TitleNewsEtravel: 'Fischer Group Launches Online Travel Portal by freshcells',
  TitleNewsSparkasse: 'S-Markt & Mehrwert relaunched with travel distribution platform by freshcells',
  TitleNewsFesto: 'Festo rolls out central product data API from freshcells',
  TitleNewsAlltours: 'freshcells Technologie ermöglicht alltours den schnellen Umstieg zu Peakwork ',
  TitleNewsLegacy: 'Legacy: Risiko und Chance zugleich, in Zeiten der digitalen Transformation, sagt Tom Hülser von freshcells',
  TitleChatGPT: 'freshcells announces integration of ChatGPT into freshMS to improve creative copy generation',
  TitleEniyan: 'eniyan GmbH presents a transformative booking and consulting platform for travel agencies at ITB in Berlin',
  TitleNezasa: 'freshcells and Nezasa present E-Commerce Platform for Customizable Travel',
  TitleSchauinsland: 'schauinsland-reisen now also relies on the TravelSandbox® from freshcells',
  TitleNewsItb2023: 'freshcells at ITB 2023 from 7 to 9 March in Berlin',
  TitleNewsInteractiveModules: 'freshcells presents new, interactive modules for its freshMS CMS solution at ITB 2023',
  TitleNewsAppstores: 'Reise-Verkaufsplattformen auch als native Apps mit der Quickstart von freshcells',
  TitleNewsIberostar: "TravelSandbox® from freshcells as a driver for HLX Touristik GmbH's business model",
  TitleNewsNextkraftwerke: 'freshcells erstellt hochmodernes Fundament für Website von Next Kraftwerke',
  TitleNewsMarvin: 'freshcells ernennt Marvin Strenger zum Chief Operating Officer',
  TitleNewsAdac: 'ADAC Reisevertrieb relaunched mit freshcells: freshcells setzt sich gegen fünf Mitbewerber durch',
  TitleSystemModernizationPage: 'IT System Modernization with freshcells',
  TitleSolutionsPage: 'Solutions for digitalization, platform development, system modernization by freshcells',
  TitleHrGeneralist: 'HR Generalist /  Recruiter career in Düsseldorf at freshcells',
  TitleContentManager: 'Content Manager',
  TitleCaseStudyAldiana: 'Aldiana booking platform relaunched by freshcells',
  TitleCaseStudyArz: 'ARZ Haan AG web application development by freshcells',
  TitleCaseStudyBedfinder: 'Hotelplan launched bedfinder booking engine with freshcells',
  TitleCaseStudyDER: 'DER Touristic Swiss multibrand booking platform Quickstart by freshcells',
  TitleCaseStudyEtravel: 'Fischer group launched eTravel booking platform with Quickstart',
  TitleCaseStudyHLX: 'HLX Touristic & Lufthansa Holidays increasing conversion with new sales channels',
  TitleCaseStudyPlattenpaner: 'Plattenplaner web application for product configuration and ordering',
  TitleCaseStudyWeekend: 'weekend.com booking platform created by freshcells',
  TitleCaseStudyYtravel: 'ytravel booking platform designed and developed by freshcells',
  TitleXmasPage: 'It’s a Christmas MAtCH: Collaborative solutions for a worry-free new year',
  TitleSeniorUiUxDesigner: '(Senior) UI / UX Designer',
  TitleAGBPage: 'ALLGEMEINE GESCHÄFTSBEDINGUNGEN',
  TitleCaseStudySmm: 'S-Markt und Mehrwert tourism platform modernization by freshcells',
  TitleCaseStudyMitsubishi: 'Mitsubishi Factory Automation. Integration of Content Management, CRM, and Web Services',
  TitleUxUi: 'Professional UX/UI Audits for digital success',
  TitleNewsAdacLaunch: 'freshcells Launches ADAC Travel Portal with Revolutionary Platform and Over 170 Affiliates',
  TitleNewsGaleriaLaunch: 'ADAC Reisen Integrates 70 Galeria Travel Agencies into the New freshcells Platform',
  TitleNewsBergeMeerLaunch: 'Digital Transformation in the Travel Industry: Berge & Meer Chooses freshcells',
  TitleNewsRunaLaunch: 'RUNA REISEN Simplifies Travel Bookings for Wheelchair Users with New Platform by freshcells and BEWOTEC'
})
